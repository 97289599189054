import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`8:00 EMOM:`}</p>
    <p>{`5-Back Squats \\@60%1RM`}</p>
    <p>{`5-Jump Squats (Jumping as high as possible)`}</p>
    <p>{`*`}<em parentName="p">{`compare to 7/22/20`}</em></p>
    <p>{`then,`}</p>
    <p>{`100/75 Calorie Assault Bike`}</p>
    <p>{`*`}{`EMOM: 10 Jumping Lunges `}<em parentName="p">{`(alternating legs, 5/leg)`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      